import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import "./Homepage.styles.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PopupPage from "../popup-deals/Popuppage";
import axios from "axios";
import { theme_airline_url } from "../../utils/apiInfo";
import BestTour from "../../components/BestTours/BestTour";
import BestTourAu from "../../components/BestTours/BestTourAu";
import BestTourCa from "../../components/BestTours/BestTourCa";
import BestTourIn from "../../components/BestTours/BestTourIn";
import BestTourNz from "../../components/BestTours/BestTourNz";
import BestTourPh from "../../components/BestTours/BestTourPh";
import BestTourQa from "../../components/BestTours/BestTourQa";
import BestTourRp from "../../components/BestTours/BestTourRp";
import BestTourSg from "../../components/BestTours/BestTourSg";
import BestTourUAE from "../../components/BestTours/BestTourUAE";
import BestTourHk from "../../components/BestTours/BestTourHk";
import BestTourMy from "../../components/BestTours/BestTourMy";
import BestTourZa from "../../components/BestTours/BestTourZa";
import BestTourTh from "../../components/BestTours/BestTourTh";
import BestTourUk from "../../components/BestTours/BestTourUk";
import BestTourVn from "../../components/BestTours/BestTourVn";
import Aeroplane from "../../assets/images/new/down.png"
import smiling from "../../assets/images/new/smiling.jpg"
import about from "../../assets/images/new/about-us.jpg"

const HomePage = () => {
  const navigate = useNavigate();
  const [res, setRes] = useState();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  const loadBestTour = () => {
    let urlname
    urlname = window.location.hostname.split('.')[0];
    if (urlname === 'localhost') {
      urlname = "au"
    } else {
      if (urlname === 'www') {
        urlname = "au"
      }
    }



    return (
      <>
        {urlname === 'Ravefares' ? <BestTour /> : ""}
        {urlname === 'au' ? <BestTourAu /> : ""}
        {urlname === 'ca' ? <BestTourCa /> : ""}
        {urlname === 'hk' ? <BestTourHk /> : ""}
        {urlname === 'in' ? <BestTourIn /> : ""}
        {urlname === 'id' ? <BestTourRp /> : ""}
        {urlname === 'my' ? <BestTourMy /> : ""}
        {urlname === 'nz' ? <BestTourNz /> : ""}
        {urlname === 'ph' ? <BestTourPh /> : ""}
        {urlname === 'qa' ? <BestTourQa /> : ""}
        {urlname === 'sg' ? <BestTourSg /> : ""}
        {urlname === 'sa' ? <BestTourZa /> : ""}
        {urlname === 'th' ? <BestTourTh /> : ""}
        {urlname === 'en' ? <BestTour /> : ""}
        {urlname === 'es' ? <BestTour /> : ""}
        {urlname === 'ae' ? <BestTourUAE /> : ""}
        {urlname === 'uk' ? <BestTourUk /> : ""}
        {urlname === 'vn' ? <BestTourVn /> : ""}
      </>
    )
  }
  // const [showPopup, setPopup] = useState('active')

  // function removePopup(){
  //   setPopup('')
  // }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const urlFull = window.location.href;
  useEffect(() => {
    axios
      .get(`${theme_airline_url}`, {
        params: {
          url: urlFull,
        },
      })
      .then((response) => {
        setRes(response.data.status);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [urlFull]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Ravefares | Home </title>
      </Helmet>
      {res === "1" ? <PopupPage /> : ""}


      <section className="head-section">
        <div className="container">
          <div className="hero-text">
            <h1>Go Tour With Ravefares</h1>
            <p>Visit Europe, America, Asia, Africa or beyond!</p>
          </div>
          <div className="booking-form-main">
            <FlightSearchForm />
          </div>
        </div>
      </section>

      <section className="gap">
        <div className="container">
          <div className="flex space-bw align-center">
            <div className="col-50">
              <div class="service-text">
                <h2>About <span>Ravefares</span></h2>
                <p>Ravefares is a leading travel company and a trusted name in the industry, known for offering the best travel deals to travelers. As an online travel booking platform, we provide a wide range of options for hotels, flights, trains, buses, and cars.</p>
                <p>Our core value lies in delivering the most reliable user experience, ensuring the fastest search and booking processes.</p>
              </div>
            </div>
            <div className="col-50 about-img">
              <img src={about} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section class="gap">
        <div class="container">
          <div class="service-text text-center">
            <h2><span>Best</span> Destinations</h2>
            <p>Discover unforgettable experiences with the best tours tailored to your adventurous spirit.</p>
          </div>
          <div class="packages flex space-bw">
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/london.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>London</h3>
                <p>Where history meets modernity in every corner</p>
                <div className="flex space-bw">
                  <div>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div>
                  <div>
                    <span>$999</span>
                  </div>
                </div>
                <button onClick={(e) => handleExpClick("LON", "NYC")}>Explore More</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/italy.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>Italy</h3>
                <p>A timeless tapestry of culture, cuisine, and captivating beauty</p>
                <div className="flex space-bw">
                  <div>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <div>
                    <span>$599</span>
                  </div>
                </div>
                <button onClick={(e) => handleExpClick("ROM", "ZGS")}>Explore Now</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/newyork.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>New York</h3>
                <p>The city where dreams touch the sky</p>
                <div className="flex space-bw">
                  <div>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <div>
                    <span>$999</span>
                  </div>
                </div>
                <button onClick={(e) => handleExpClick("NYC", "LON")}>Explore Now</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/tokyo.jpg")} alt="" /></div>
              <div class="package-text-top">
                <h3>Tokyo</h3>
                <p>A vibrant fusion of tradition and innovation</p>
                <div className="flex space-bw">
                  <div>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div>
                  <div>
                    <span>$399</span>
                  </div>
                </div>
                <button onClick={(e) => handleExpClick("TYO", "NRT")}>Explore Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gap">
        <div className="container">
          <div className="calltoact flex space-bw">
            <div className="col-40 smiling-girl">
              <img src={smiling} alt="" />
              <span>
                <i class="fa-solid fa-mobile-screen-button fa-shake"></i>
              </span>
            </div>
            <div className="col-60 smiling-girl">
              <p>Call now for immediate assistance! With our no-wait policy, you’ll receive the help you need right away. Our dedicated team is available 24/7, ready to provide prompt, efficient service. Don’t hesitate—dial our number and experience quick, personalized support without delay!</p>
              <a href="tel:+18447923620"><i className="fa-solid fa-phone-volume fa-shake"></i>+1-844-792-3620</a>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment >
  );
};

export default HomePage;
